@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calistoga&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
}

header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px;
}

#header-content {
    display: flex;
    align-items: center;
    gap: 30px;
}

#header-image {
    width: 130px;
    height: auto;
}

#header-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#header-titulo {
    font-family: "Roboto Mono";
    font-weight: 400;
    color: #5b4d32;
}

#header-lista {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

#header-lista-item {
    list-style: none;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: #00000080;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
} 

#header-lista-item:hover {
    transform: scale(1.1);
}



@media (max-width: 844px) {
    #header-content {
        gap: 10px;
        justify-content: center;
    }

    header {
        display: block;

    }

    #header-image {
        width: 100px;
    }

    #header-titulo {
        font-size: 20px;
    }

    #header-nav {
        display: none;
    }
}

#separador {
    text-align: center;
    padding: 20px 0;
    background-color: #db6937;
}

#separador span {
    font-family: "Calistoga", sans-serif;
    font-size: 40px;
    color: white;
    font-weight: bold;

}

@media (max-width: 844px) {
    
    #separador span {
        font-size: 20px;
    }
}


main {
    display: flex;
    justify-content: space-evenly;
    padding: 50px 0;
}

#main-content {
    max-width: 700px;
    margin-top: 100px;
    text-align: center;
    margin-left: 20px;
}

#main-title {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 35px;
    color: #db6937;
    border-bottom: 1px solid #00000010;
}

#main-p {
    line-height: 1.6;
    color: #2e3737;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
}

#main-image {
    width: 400px;
    height: auto;
}

@media (max-width: 844px) {
    main {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #main-content {
        margin-top: 50px;
        margin-left: 0px;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #main-p {
        max-width: 300px;
    }

    #main-title {
        border: none;
    }
}

#imagem-div {
    height: 350px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("../../assets/criancas-felizes.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: 50% 50%;
}

#imagem-div::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0) 40%, #000 100%);
    background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 40%, #000 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 40%, #000 100%);
}
/*
#imagem-div-imagem {
    position: absolute;
    width: 100%;
    object-fit: cover;
    background-attachment: fixed;
}
*/
#imagem-texto {
    max-width: 1000px;
    font-size: 35px;
    color: white;
    z-index: 2;
    font-family: "Calistoga", sans-serif;
}

@media (max-width: 844px) {
    #imagem-texto {
        font-size: 20px;
    }

    #imagem-div {
        height: 200px;
        width: 100%;
    }


}

#quem-somos {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: whitesmoke;
    padding: 70px 0;
}

#quem-somos-image {
    width: 450px;
}

#quem-somos-content {
    text-align: center;
    max-width: 650px;
}

.quem-somos-title {
    margin-bottom: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    color: #624a1e;
}

.quem-somos-div {
    margin-top: 20px;
}

.quem-somos-text {
    line-height: 1.6;
    color: #2e3737;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
}

@media (max-width: 844px) {
    #quem-somos {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #quem-somos-image {
        width: 400px;
        height: 200px;
        margin-bottom: 30px;
    
    }

    .quem-somos-text {
        max-width: 300px;
        
    }

    #quem-somos-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 400px;
    }
}

footer {
    display: flex;
    padding: 50px 0;
    justify-content: space-around;
    font-family: "Roboto", sans-serif;
    background-color: #db6937;
    text-align: center;
}

#footer-section {
    max-width: 500px;
}

#footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

#footer-image {
    width: 15%;
}

#footer-title {
    font-size: 2rem;
    color: whitesmoke;
    font-family: "Calistoga", sans-serif;
}

#footer-texto {
    line-height: 1.6;
    color: #2e3737;
    font-size: 1.1rem;
}

footer h3 {
    color: whitesmoke;
    font-size: 1.3rem;
    font-family: "Roboto", sans-serif;
    margin-bottom: 2px;
}

.footer-lista-item, .footer-infos-link {
    transition: 0.2s;
    cursor: pointer;
    text-decoration: none;
    color: #2e3737;
    list-style: none;
    margin-bottom: 5px;
}

.footer-lista-item:hover, .footer-infos-link:hover {
    transform: scale(1.02);
    opacity: 0.7;
}

#footer-redes {
    display: flex;
    flex-direction: column;
    text-align: center;
}

@media (max-width: 844px) {
    footer {
        flex-direction: column;
    }

    #footer-section {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    #footer-texto {
        max-width: 300px;
    }
}